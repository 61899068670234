<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less">
* {
  padding: 0;
  margin: 0;
}
body,
p {
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden !important;
}

a {
  color: #000;
}
a.active {
  color: #900c0c;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.cen_box img,
.con_box img,
.cen img,
.cen_right img {
  display: inline-block;
  max-width: 100% !important;
  height: auto;
  border-radius: 5px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}
</style>
