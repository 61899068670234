<template>
  <div class="not-defined">
    <img src="@assets/images/404.gif" />

    <div class="btn" @click="$router.replace({ path: '/' })">
      <!-- 返回首页 -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NotDefined",
};
</script>

<style scoped>
.not-defined img {
  width: 100%;

  /* margin-top: 18%; */
}

.not-defined {
  position: relative;
}

/* .content {
  padding: 0 1rem;
  text-align: center;
  color: #44405e;
  font-size: 15px;
}

.title {
  margin-bottom: 0.6rem;
  color: #302c48;
  font-size: 20px;
} */

.btn {
  position: absolute;
  /* background: red; */
  width: 60px;
  height: 20px;
  left: 49%;
  top: 648px;
}
</style>
