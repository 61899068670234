import Vue from "vue";
import Router from "vue-router";
import $store from "../store";
import toLogin from "@libs/login";
import NotDefined from "@views/NotDefined";
Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            name: "/",
            meta: {
                title: "中小学传统文化数字资源研究传播云平台",
                keepAlive: true,
                auth: false,
            },
            component: () => import("@views/Index.vue"),
        },

        /*------------------ 文章相关-------------- */

        //焦点新闻列表
        {
            path: "/article",
            name: "/article",
            meta: {
                article_category_id: 4,
                title: "焦点新闻列表",
                background: "images/news_bg.jpg",
                auth: false,
            },
            component: () => import("@views/article/ArticleList.vue"),
        },

        //新闻
        {
            path: "/article/detail",
            name: "/article/detail",
            meta: {
                title: "焦点新闻详情",
                background: "images/pingtaijieshao.jpg",
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //人教版全国中小学传统文化教学一体机
        {
            path: "/article/primaryEquip",
            name: "/article/primaryEquip",
            meta: {
                id: 7,
                title: "人教版全国中小学传统文化教学一体机",
                background: "images/fuzhu.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //中小学传统文化融媒体资源研究中心配套校园终端服务器
        {
            path: "/article/campusServer",
            name: "/article/campusServer",
            meta: {
                id: 966,
                title: "中小学传统文化融媒体资源研究中心配套校园终端服务器",
                background: "images/fuzhu.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //发展历程咨讯
        {
            path: "/article/developmentHistory",
            name: "/article/developmentHistory",
            meta: {
                id: 16,
                title: "发展历程咨讯",
                background: "images/falvshengming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //法律声明
        {
            path: "/application/legal",
            name: "/application/legal",
            meta: {
                id: 1,
                title: "法律声明",
                background: "images/falvshengming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //资源中心简介
        {
            path: "/article/resourceIntroduction",
            name: "/article/resourceIntroduction",
            meta: {
                id: 19,
                title: "资源中心简介",
                background: "images/falvshengming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //校长关爱工程
        {
            path: "/application/xiaozhangGa",
            name: "/application/xiaozhangGa",
            meta: {
                id: 18,
                title: "校长关爱工程",
                background: "images/falvshengming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        //中小学师资培训
        {
            path: "/application/zhongxiaoxueshizi",
            name: "/application/zhongxiaoxueshizi",
            meta: {
                id: 17,
                title: "中小学师资培训",
                background: "images/falvshengming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/article/ArticleDetail.vue"),
        },

        /*------------------ 文章相关结束-------------- */

        //理论研究中心
        {
            path: "/theory",
            name: "/theory",
            meta: {
                title: "理论研究中心",
                background: "images/theoryBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/theory/theory.vue"),
        },

        {
            path: "/theory/detail",
            name: "/theory/detail",
            meta: {
                title: "理论研究中心详情",
                background: "images/theoryDetailBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/theory/theoryDetail.vue"),
        },

        //国学微课程专区(登录模块)
        {
            path: "/video/microCourse",
            name: "/video/microCourse",
            meta: {
                id: 20,
                title: "国学微课程专区",
                path: "/video/microCourse/detail",
                background: "images/microCourseBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoCategory.vue"),
        },

        //国学微课程专区详情(登录模块)
        {
            path: "/video/microCourse/detail",
            name: "/video/microCourse/detail",
            meta: {
                id: 20,
                title: "国学微课程详情",
                path: "/video/microCourse/detail",
                background: "images/microCourseDetailBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/encrypVideoDetail.vue"),
        },

        //社会主义核心价值观教育(登录模块)
        {
            path: "/video/videoCategoryshehui",
            name: "/video/videoCategoryshehui",
            meta: {
                id: 21,
                title: "社会主义核心价值观教育",
                path: "/video/videoCategoryshehui/detail",
                background: "images/shehuizhuyi.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoCategoryshehui.vue"),
        },

        //社会主义核心价值观教育详情(登录模块)
        {
            path: "/video/videoCategoryshehui/detail",
            name: "/video/videoCategoryshehui/detail",
            meta: {
                id: 20,
                title: "社会主义核心价值观教育",
                path: "/video/videoCategoryshehui/detail",
                background: "images/microCourseDetailBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/encrypVideoDetail.vue"),
        },

        //幼儿字源识字
        {
            path: "/video/read",
            name: "/video/read",
            meta: {
                id: 35,
                title: "幼儿字源识字",
                path: "/video/readCourseDetail",
                background: "images/readCourseBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoCategory.vue"),
        },

        //幼儿字源识字详情
        {
            path: "/video/readCourseDetail",
            name: "/video/readCourseDetail",
            meta: {
                id: 35,
                title: "幼儿字源识字详情",
                path: "/video/readCourseDetail",
                background: "images/readCourseDetailBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/encrypVideoDetail.vue"),
        },

        //单独播放视频
        {
            path: "/video/singlePlayVideo",
            name: "/video/singlePlayVideo",
            meta: {
                title: "主题阅读",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/singlePlayVideo.vue"),
        },

        {
            path: "/login",
            name: "/login",
            meta: {
                title: "登录",
                auth: false,
            },
            component: () => import("@views/login.vue"),
        },

        //激活
        {
            path: "/activation",
            name: "/activation",
            meta: {
                title: "账号激活",
                detailPath: "/activation",
                path: "/activation",
                keepAlive: false,
            },
            component: () => import("@views/activation.vue"),
        },
        //普通用户注册
        {
            path: "/register",
            name: "/register",
            meta: {
                title: "注册",
                auth: false,
            },
            component: () => import("@views/register.vue"),
        },

        //单位用户注册
        {
            path: "/unitRegister",
            name: "/unitRegister",
            meta: {
                title: "注册",
                auth: false,
            },
            component: () => import("@views/unitRegister.vue"),
        },

        // 传统课程体系
        {
            path: "/tradition",
            name: "/tradition",
            meta: {
                title: "传统课程体系",
                background: "images/traditoinBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/tradition/tradition.vue"),
        },

        {
            path: "/tradition/detail",
            name: "/tradition/detail",
            meta: {
                title: "传统课程体系详情",
                background: "images/traditionDetailBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/tradition/traditionDetail.vue"),
        },

        // ----------------------------以下是创新课程和综合课程相关的router-----------------------------//

        //创新课程体系辅助阅读
        {
            path: "/practice/AuxiliaryReading",
            name: "/practice/AuxiliaryReading",
            meta: {
                title: "创新课程体系辅助阅读",
                detailPath: "/practice/AuxiliaryReading",
                path: "/practice/AuxiliaryReading",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/AuxiliaryReading.vue"),
        },

        //教师备课课程体系列表（教师手册）
        {
            path: "/preparation",
            name: "/preparation",
            meta: {
                title: "教师资源/教师备课资源",
                background: "images/jiao.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/preparation/Preparation.vue"),
        },

        //教师备课课程体系详情（教师手册）
        {
            path: "/preparation/detail",
            name: "/preparation/detail",
            meta: {
                title: "教师资源/教师备课资源详情",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/preparation/PreparationDetail.vue"),
        },

        //多媒体资源中心
        {
            path: "/video/duomei",
            name: "/video/duomei",
            meta: {
                id: 1,
                title: "多媒体资源中心",
                path: "/video/duomeiDetail",
                background: "images/duomeiBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoCategory.vue"),
        },

        //多媒体资源中心详情
        {
            path: "/video/duomeiDetail",
            name: "/video/duomeiDetail",
            meta: {
                id: 1,
                title: "多媒体资源详情",
                path: "/video/duomeiDetail",
                keepAlive: false,
                background: "images/duomeiDetailBg.jpg",
                auth: false,
            },
            component: () => import("@views/video/videoDetail.vue"),
        },

        //师资培训专区
        {
            path: "/video/train",
            name: "/video/train",
            meta: {
                id: 10,
                title: "师资培训专区",
                path: "trainDetail",
                background: "images/trainBg2.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/enTrain.vue"),
        },

        //招生简章
        {
            path: "/train/recruitStudents",
            name: "/train/recruitStudents",
            meta: {
                id: 4,
                title: "招生简章",
                background: "images/zhaosheng.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/train/recruitStudents.vue"),
        },

        //报名须知
        {
            path: "/train/baomingxuzhi",
            name: "/train/baomingxuzhi",
            meta: {
                id: 6,
                title: "报名须知",
                background: "images/baoming.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/train/baomingxuzhi.vue"),
        },

        //人才库
        {
            path: "/train/talentPool",
            name: "/train/talentPool",
            meta: {
                title: "人才库",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/train/talentPool.vue"),
        },

        //证书样式
        {
            path: "/train/zhengshuyangshi",
            name: "/train/zhengshuyangshi",
            meta: {
                id: 5,
                title: "证书样式",
                background: "images/zhengshuYc.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/train/zhengshuyangshi.vue"),
        },

        //报名
        {
            path: "/train/signUp",
            name: "/train/signUp",
            meta: {
                title: "教师培训报名",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/train/signUp.vue"),
        },

        //视频培训专区（登录模块）
        {
            path: "/video/videoTraining",
            name: "/video/videoTraining",
            meta: {
                title: "视频培训专区",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoTraining.vue"),
        },

        //视频培训专区详情（登录模块）
        {
            path: "/video/videoTraining/list",
            name: "/video/videoTraining/list",
            meta: {
                title: "视频培训专区",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoTrainingList.vue"),
        },

        //视频培训专区详情（需登录模块）
        {
            path: "/video/videoTraining/list/detail",
            name: "/video/videoTraining/list/detail",
            meta: {
                title: "视频培训专区详情",
                path: "/video/videoTraining/list/detail",
                background: "images/enTrainDetail.jpg",
                auth: false,
            },
            component: () => import("@views/video/encrypVideoDetail.vue"),
        },

        //师资培训专区(登录模块)
        {
            path: "/video/enTrain",
            name: "/video/enTrain",
            meta: {
                id: 10,
                title: "师资培训中心",
                path: "enTrainDetail",
                background: "images/trainBg2.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/enTrain.vue"),
        },

        //优秀示范课(登录模块)
        {
            path: "/video/modelCourse",
            name: "/video/modelCourse",
            meta: {
                id: 17,
                title: "优秀示范课",
                path: "/video/modelCourse/detail",
                background: "images/modelCourseBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/videoCategory.vue"),
        },

        //优秀示范课详情(登录模块)
        {
            path: "/video/modelCourse/detail",
            name: "/video/modelCourse/detail",
            meta: {
                id: 17,
                title: "优秀示范课详情",
                path: "/video/modelCourse/detail",
                background: "images/enModelCourseDetail.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/encrypVideoDetail.vue"),
        },

        //综合实践课程（需登录模块）
        {
            path: "/practice",
            name: "/practice",
            meta: {
                title: "综合实践课程设计",
                keepAlive: false,
                background: "images/zon.jpg",
                auth: false,
            },
            component: () => import("@views/practice/Practice.vue"),
        },

        //创新课程体系国学模块（需登录）
        {
            path: "/practice/guoxue",
            name: "/practice/guoxue",
            meta: {
                id: 2,
                title: "创新课程体系国学模块",
                detailPath: "/practice/guoxueDetail",
                path: "/practice/guoxue",
                background: "images/practiceGxThemeBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/PracticeTheme.vue"),
        },

        //创新课程体系国学模块详情（需登录）
        {
            path: "/practice/guoxueDetail",
            name: "/practice/guoxueDetail",
            meta: {
                id: 2,
                title: "创新课程体系国学模块详情",
                detailPath: "/practice/guoxueDetail",
                path: "/practice/guoxue",
                pathUrl: "/practice/AuxiliaryReading",
                background: "images/practiceGxThemeBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/PracticeThemeDetail.vue"),
        },

        //创新课程体系民族文化与艺术模块（需登录）
        {
            path: "/practice/nationCulture",
            name: "/practice/nationCulture",
            meta: {
                id: 1,
                title: "创新课程体系民族文化与艺术模块",
                detailPath: "/practice/nationCultureDetail",
                path: "/practice/nationCulture",
                background: "images/practiceNationCultureBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/PracticeTheme.vue"),
        },

        //创新课程体系民族文化与艺术模块详情（需登录）
        {
            path: "/practice/nationCultureDetail",
            name: "/practice/nationCultureDetail",
            meta: {
                id: 1,
                title: "创新课程体系民族文化与艺术模块详情",
                detailPath: "/practice/nationCultureDetail",
                path: "/practice/nationCulture",
                pathUrl: "/practice/AuxiliaryReading",
                background: "images/practiceNationCultureBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/PracticeThemeDetail.vue"),
        },

        //中国历史模块（需登录）
        {
            path: "/practice/history",
            name: "/practice/history",
            meta: {
                id: 3,
                title: "中国历史模块",
                detailPath: "/practice/historyDetail",
                path: "/practice/history",
                background: "images/practiceHistoryBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/practice/PracticeTheme.vue"),
        },

        //中国历史模块详情（需登录）
        {
            path: "/practice/historyDetail",
            name: "/practice/historyDetail",
            meta: {
                id: 3,
                title: "中国历史模块",
                detailPath: "/practice/historyDetail",
                path: "/practice/history",
                background: "images/practiceHistoryBg.jpg",
                keepAlive: false,
                pathUrl: "/practice/AuxiliaryReading",
                auth: false,
            },
            component: () => import("@views/practice/PracticeThemeDetail.vue"),
        },

        //中医启蒙模块（需登录）
        {
            path: "/practice/medicine",
            name: "/practice/medicine",
            meta: {
                title: "中医启蒙",
                path: "/practice/medicine",
                detailPath: "/practice/medicineDetail",
                background: "images/practiceMedicineBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () =>
                import("@views/practice/PracticeMedicineTheme.vue"),
        },

        //中医启蒙模块详情（需登录）
        {
            path: "/practice/medicineDetail",
            name: "/practice/medicineDetail",
            meta: {
                title: "中医启蒙详情",
                detailPath: "/practice/medicineDetail",
                path: "/practice/medicine",
                background: "images/practiceMedicineBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () =>
                import("@views/practice/PracticeMedicineThemeDetail.vue"),
        },

        //传统文化中高考模拟试题
        {
            path: "/exam",
            name: "/exam",
            meta: {
                title: "传统文化中高考模拟试题",
                keepAlive: false,
                auth: true,
            },
            component: () => import("@views/exam/Exam.vue"),
        },

        //学科教研专区
        {
            path: "/ProjectScientific",
            name: "/ProjectScientific",
            meta: {
                title: "学科教研专区",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/project/ProjectScientific.vue"),
        },

        //课题申报专区
        {
            path: "/application",
            name: "/application",
            meta: {
                title: "课题申报专区",
                background: "images/application.png",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/application/Application.vue"),
        },

        //辅助教学产品目录
        {
            path: "/goods/goodsCategory",
            name: "/goods/goodsCategory",
            meta: {
                title: "辅助教学产品目录",
                path: "/goods/goods",
                background: "images/catalog.png",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/goods/GoodsCategory.vue"),
        },

        //辅助教学产品目录详情(图书商品)
        {
            path: "/goods/goods",
            name: "/goods/goods",
            meta: {
                title: "辅助教学产品目录",
                path: "/goods/goods",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/goods/Goods.vue"),
        },

        //课题研究介绍
        {
            path: "/project",
            name: "/project",
            meta: {
                title: "课题研究介绍",
                background: "images/ketijieshaoBg.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/project/Project.vue"),
        },

        //课题研究介绍详情
        {
            path: "/project/list",
            name: "/project/list",
            meta: {
                title: "课题研究介绍详情",
                pathlink: "/goods/goods",
                background: "images/ketijieshao2.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/project/ProjectList.vue"),
        },

        //十二五课题研究成果介绍详情
        {
            path: "/project/detail",
            name: "/project/detail",
            meta: {
                title: "课题研究介绍详情",
                background: "images/fuzhu.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/project/ProjectDetail.vue"),
        },

        //发展历程
        {
            path: "/development",
            name: "/development",
            meta: {
                title: "发展历程",
                background: "images/development.png",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/development/Development.vue"),
        },

        //发展历程详情
        {
            path: "/development/detail",
            name: "/development/detail",
            meta: {
                title: "发展历程",
                path: "/application/developmentDetail",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/development/DevelopmentDetail.vue"),
        },

        //基地名录
        {
            path: "/application/jidiminglu",
            name: "/application/jidiminglu",
            meta: {
                title: "基地名录",
                auth: false,
            },
            component: () => import("@views/application/jidiminglu.vue"),
        },

        //个人中心
        {
            path: "/user",
            name: "/user",
            meta: {
                title: "个人中心",
                path: "/video/enTrainVideoDetail",
                keepAlive: false,
                auth: true,
            },
            component: () => import("@views/user.vue"),
        },

        //支付页面
        {
            path: "/payment",
            name: "/payment",
            meta: {
                title: "支付页面",
                keepAlive: false,
                background: "images/payment.jpg",
                auth: true,
            },
            component: () => import("@views/payment.vue"),
        },
        //标准化课程推荐
        {
            path: "/application/biaozhunTj",
            name: "/application/biaozhunTj",
            meta: {
                id: 12,
                title: "标准化课程推荐",
                detailPath: "/application/biaozhunTj",
                path: "/application/biaozhunTj",
                auth: false,
            },
            component: () => import("@views/application/biaozhunTj.vue"),
        },

        //师资培训学习页面
        {
            path: "/video/enTrainVideoDetail",
            name: "/video/enTrainVideoDetail",
            meta: {
                id: 1,
                title: "师资培训学习",
                background: "images/enTrainDetail2.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/video/enTrainVideoDetail.vue"),
        },

        //申报表
        {
            path: "/application/shenbaobiao",
            name: "/application/shenbaobiao",
            meta: {
                title: "申报表",
                background: "images/falvshengming.jpg",
                auth: true,
            },
            component: () => import("@views/application/shenbaobiao.vue"),
        },

        //电子书阅读二级栏目
        {
            path: "/ebooksCategory",
            name: "/ebooksCategory",
            meta: {
                title: "电子书阅读分类",
                background: "images/ebooksCategory.jpg",
                keepAlive: false,
                auth: false,
            },
            component: () => import("@views/ebooks/ebooksCategory.vue"),
        },

        //电子书阅读三级栏目
        {
            path: "/ebooksCategory/ebooks",
            name: "/ebooksCategory/ebooks",
            meta: {
                title: "电子书阅读",
                background: "images/ebooksCategory.jpg",
                keepAlive: false,
                auth: true,
            },
            component: () => import("@views/ebooks/ebooks.vue"),
        },

        //电子书阅读详情页
        {
            path: "/ebooksCategory/ebooks/detail",
            name: "/ebooksCategory/ebooks/detail",
            meta: {
                title: "电子书阅读",
                background: "images/ebooksCategory.jpg",
                keepAlive: false,
                auth: true,
            },
            component: () => import("@views/ebooks/ebookDetail.vue"),
        },

        //中华优秀传统文化课题研究地区工作站
        {
            path: "/xuekejiaoyan/workstation",
            name: "/xuekejiaoyan/workstation",
            meta: {
                id: 27,
                title: "中华优秀传统文化课题研究地区工作站名录",
                background: "images/region_workstation_bg.jpg",
                auth: false,
            },
            component: () => import("@views/workstation/workstation.vue"),
        },

        //资源展示
        {
            path: "/resourceDisplay",
            name: "/resourceDisplay",
            meta: {
                title: "资源展示",
                background: "images/resource_display_bg.jpg",
                auth: false,
            },
            component: () => import("@views/resourceDisplay.vue"),
        },

        //校园健康网
        {
            path: "/campusHealth",
            name: "/campusHealth",
            meta: {
                title: "校园健康",
                auth: false,
            },
            component: () => import("@views/campusHealth/CampusHealth.vue"),
        },

        //校园健康网栏目分类
        {
            path: "/campusHealth/category",
            name: "/campusHealth/category",
            meta: {
                title: "校园健康栏目分类",
                auth: false,
            },
            component: () =>
                import("@views/campusHealth/CampusHealthCategory.vue"),
        },

        //校园健康网栏目分类=>下级列表
        {
            path: "/campusHealth/category/list",
            name: "/campusHealth/category/list",
            meta: {
                title: "校园健康栏目分类列表",
                auth: false,
            },
            component: () =>
                import("@views/campusHealth/CampusHealthCategoryList.vue"),
        },

        //校园健康网（文章）详情
        {
            path: "/campusHealth/detail",
            name: "/campusHealth/detail",
            meta: {
                title: "校园健康网文章详情",
                auth: false,
            },
            component: () =>
                import("@views/campusHealth/CampusHealthDetail.vue"),
        },

        //校园健康网=>传统文化
        {
            path: "/campusHealth/video",
            name: "/campusHealth/video",
            meta: {
                title: "传统文化",
                auth: false,
            },
            component: () =>
                import("@views/campusHealth/CampusHealthVideo.vue"),
        },

        //校园健康网=>传统文化
        {
            path: "/campusHealth/video/detail",
            name: "/campusHealth/video/detail",
            meta: {
                title: "传统文化",
                auth: false,
            },
            component: () =>
                import("@views/campusHealth/CampusHealthVideoDetail.vue"),
        },

        //页面找不到
        {
            path: "*",
            name: "NotDefined",
            meta: {
                title: "页面找不到",
                keepAlive: true,
                home: false,
            },
            component: NotDefined,
        },
    ],
});

router.beforeEach((to, form, next) => {
    const { auth } = to.meta;
    if (auth === true && !$store.state.app.token) {
        if (form.name === "Login") return;
        return toLogin(true, to.fullPath);
    }
    if (auth) {
        $store.dispatch("USERINFO").then(() => {
            next();
        });
    } else next();
});
export default router;
