import "babel-polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.less";
import axios from "axios";
import dialog from "./utils/dialog";

import "jquery";
import "jquery.nicescroll";
import "amfe-flexible";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
// require('es6-promise').polyfill();
// require('es6-promise/auto');
Vue.use(ElementUI);
Vue.use(Vant);
Vue.prototype.$ajax = axios;
Vue.prototype.$dialog = dialog;

router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
});

// Vue.config.productionTip = false
const $vm = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
